<template>
  <v-dialog width="570" v-model="isShowModal" persistent>
    <v-card class="px-4" style="position: relative">
      <div v-if="timeStatus === 'during'" style="position: absolute; top:3px;right: 8px">
        <v-btn icon @click="closePopup()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <div class='mt-8 mb-4' style="color: rgba(0, 0, 0, 0.87);">
        <div class='d-flex justify-center align-center'>
          <img :width="140" src="../../assets/mascot-1.png">
        </div>
        <div class="text-center my-4 px-4">
          <div style="font-weight: 600;font-size: 16px" >Nhà trường Vuihọc trân trọng thông báo</div>
          <div style="font-style: italic"  class="mt-1">
            RINOEDU là một thương hiệu mới của Vuihọc dành cho các Chương trình gia sư
            Quốc tế như Tiếng Anh Cam - Ielts - Station, Toán tư duy.
          </div>
          <div style="font-weight: 600" class="mt-2">
            <span style="color: #FF6500 !important;">Từ ngày {{ redirectDomain.start_time ? formatDateVN(redirectDomain.start_time) : '' }}, </span>
            giáo viên và các phòng ban liên quan đăng nhập và sử dụng hệ thống quản trị mới tại
            <span style="color: #FF6500 !important">{{ redirectDomain && redirectDomain.new_domain ?  redirectDomain.new_domain : ''}}</span>
          </div>
          <div class="mt-2" style="font-style: italic;font-weight: 500;">
            <span style=" background: rgba(90, 214, 255, 1); width: max-content;padding: 2px 16px;border-radius: 4px;">Đăng nhập hệ thống mới bằng tài khoản Vuihọc cũ của bạn</span>
          </div>
        </div>
        <div
            :style="`display: grid;column-gap: 12px; grid-template-columns:${ timeStatus === 'during' ? 'repeat(2, minmax(0, 1fr))' : 'repeat(1, minmax(0, 1fr))' }`" >
          <v-btn @click="handleRedirect()"
                 color="#FF5500"
                 style="text-transform: none !important;
                          color: white !important;
                          font-size: 14px;
                          box-shadow: none;
                          margin-right: 0 !important;
                          font-weight: 400 !important;"
          >Đăng nhập {{ redirectDomain && redirectDomain.new_domain ?  redirectDomain.new_domain : ''}}
          </v-btn>
          <v-btn v-if="timeStatus === 'during'" @click="closePopup()"
                 style="
                   text-transform: none !important;
                   font-size: 14px;
                   border: 1px solid rgba(255, 102, 9, 1);
                   color: rgba(255, 102, 9, 1) !important;
                   background: white !important;
                   box-shadow: none;
                   margin-right: 0 !important;"
          >Bỏ qua </v-btn>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
import {mapState} from "vuex";
import * as moment from "moment";
import TokenService from "../../helper/token";
import _ from "lodash";

export default {
  name: 'LoginReminderPopup',
  data: () => {
    return {
      isShowModal: false,
      TEACHER_FILE_CLASSIFICATION: [
        {id: 1, name: 'CAM - Cambridge'},
        {id: 3, name: 'SB - Skill'},
        {id: 4, name: 'PLUS - Skill Plus'},
        {id: 5, name: 'IELTS - Ielts'},
        {id: 7, name: 'TTH - Kindie'},
        {id: 15, name: 'TTD - Toán tư duy'},
      ]
    }
  },
  computed: {
    ...mapState(['redirectDomain', 'listUserNeedRedirect', 'listUserException']),
    timeStatus() {
      const currentDate = moment(this.redirectDomain.current_time)
      const startDate = moment(this.redirectDomain.start_time)
      const endDate = moment(this.redirectDomain.end_time)
      if (currentDate.isBefore(startDate)) {
        return 'before'
      } else if (currentDate.isAfter(endDate)) {
        return 'after'
      } else {
        return 'during'
      }
    },
    shouldShowPopupForUser() {
      const teacherInfo = TokenService.getTeacherInfo()
      const teacherFile =  _.get(teacherInfo, 'teacherFile')
      const username = _.get(teacherInfo, 'username')

      return (this.TEACHER_FILE_CLASSIFICATION.find(item => item.id == teacherFile) && !this.listUserException.includes(username)) || this.listUserNeedRedirect.includes(username)
    }
  },
  created() {
    this.checkPopupDisplay()
  },
  methods: {
    checkPopupDisplay() {
      console.log(this.timeStatus)
      if (this.timeStatus === 'during') {
        if(this.shouldShowPopupForUser) {
          const lastClosedTime = localStorage.getItem('popupLastClosed')
          const currentTime = new Date().getTime()
          if (!lastClosedTime || (currentTime - parseInt(lastClosedTime)) > 12 * 60 * 60 * 1000) {
            this.isShowModal = true
          } else {
            this.isShowModal = false
          }
        }
      } else if (this.timeStatus === 'after') {
        if (this.shouldShowPopupForUser) {
          this.isShowModal = true
        }
      }
    },

    closePopup() {
      this.isShowModal = false
      localStorage.setItem('popupLastClosed', new Date().getTime().toString())
    },
    handleRedirect() {
      this.isShowModal = false
      window.location.href = 'https://' + this.redirectDomain.new_domain
    },
  }
}
</script>